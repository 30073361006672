import { WarningTriangle } from '@berlitz/icons'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  GenericErrorModalContent,
  IconCircle,
  Spacing,
  P,
  H1,
  ImageContainer,
  ButtonGroup,
  ButtonSpacing,
} from './style'
import TransactionalModalContent from '@berlitzplatforms/micro.modal.transactional'
import router from 'next/router'
import Button from '@berlitz/button'

interface GenericErrorContentProps {
  title: string
  description: string
  onClose: Function
  hideSupportBtn?: boolean
  hideCloseBtn?: boolean
}

export const Content: React.FC<GenericErrorContentProps> = ({
  title,
  description,
  onClose,
  hideSupportBtn = false,
  hideCloseBtn = false,
}) => {
  const goToSupport = () => {
    router.push('/support/contact-us')
    onClose()
  }

  return (
    <GenericErrorModalContent>
      <ImageContainer>
        <IconCircle>
          <WarningTriangle size="xl" />
        </IconCircle>
      </ImageContainer>
      <Spacing className="xl" />
      <Spacing className="xxs" />
      <H1>
        <FormattedMessage id={title} defaultMessage={title} />
      </H1>
      <Spacing className="xs" />
      <Spacing className="xxs" />
      {description ? (
        <P>
          <FormattedMessage id={description} defaultMessage={description} />
        </P>
      ) : null}
      <ButtonSpacing />
      <ButtonGroup>
        {hideCloseBtn ? null : (
          <Button onClick={onClose} buttonType="default">
            <FormattedMessage id="Close" defaultMessage="Close" />
          </Button>
        )}
        {!hideSupportBtn && (
          <Button onClick={goToSupport} buttonType="default">
            {<FormattedMessage id="Request support" defaultMessage="Request support" />}
          </Button>
        )}
      </ButtonGroup>
    </GenericErrorModalContent>
  )
}

export const MicroContent: React.FC<GenericErrorContentProps> = ({ onClose, title, description }) => {
  const goToSupport = () => {
    router.push('/support/contact-us/?id=feedback&cat=Other')
  }

  return (
    <TransactionalModalContent
      image={
        <IconCircle>
          <WarningTriangle size="lg" />
        </IconCircle>
      }
      title={<FormattedMessage id={title} defaultMessage={title} />}
      description={<FormattedMessage id={description} defaultMessage={description} />}
      onClose={onClose}
      onProceed={goToSupport}
      proceedBtnLabel={<FormattedMessage id="Close" defaultMessage="Close" />}
      closeBtnLabel={<FormattedMessage id="Request support" defaultMessage="Request support" />}
    />
  )
}

export default Content
